import Repository from "./Repository";

export default {
  getSpecies() {
    return Repository.get(`/species`);
  },

  getSingleSpecies(code) {
    return Repository.get(`/species/${code}`);
  },

  getSpeciesCatches() {
    return Repository.get(`/species/catches`);
  },

  getRelatedCatchesForSpecies(id) {
    return Repository.get(`/species/${id}/related`);
  },

  getBycatchForSpecies(id) {
    return Repository.get(`/species/${id}/bycatchPercent`);
  },

  getPostionsForSpecies(id) {
    return Repository.get(`/species/${id}/position`);
  },

  getAbsencesForSpecies(id) {
    return Repository.get(`/species/${id}/absence`);
  },

  getAnnualCatchesForSpecies(id) {
    return Repository.get(`/species/${id}/catches`);
  },

  getSpeciesWeights() {
    return Repository.get(`/species/weights`);
  },

  getSpeciesBiomass() {
    return Repository.get(`/species/biomass`);
  },

  getSpeciesBiomassYears() {
    return Repository.get(`/species/biomass/years`);
  },

  getSingleSpeciesBiomass(id) {
    return Repository.get(`/species/${id}/biomass`);
  },

  getSpeciesPreyData() {
    return Repository.get(`/species/prey`);
  },

  getSpeciesOverviewData() {
    return Repository.get(`/species/overview`);
  }
  
};
