import axios from "axios"

const baseUrl = "/api/"

// Create the axios object
const repo = axios.create({
    baseURL: baseUrl,
});

repo.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

export default repo;
