import React from "react";
import { RepositoryFactory } from "../repositories/RepositoryFactory";
import Plot from "react-plotly.js";
import { setSpecies, setSecondarySpecies } from "../redux/actions/speciesAction";
import { connect } from "react-redux";
import SpeciesDetails from "./SpeciesDetails";
import history from '../history';

const mapStateToProps = state => {
  return { selected_species: state.selectedSpecies };
};

class TrawlChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      speciesRepository: RepositoryFactory.get("species"),
      related_species: [],
      species_bycatch: [],
      sortingValue: "percent",
      width: 100
    };
  }

  componentDidMount() {
    // Initialises all the related species array for all species.
    this.props.toggle("Species Details");
    this.state.speciesRepository
      .getBycatchForSpecies(this.props.selected_species.code)
      .then(response => {
        this.setState({ related_species: response.data });
      });
  }

  componentDidUpdate(prevProps) {
    // If the species list has updated, that is the user has clicked on another species.
    if (this.props.selected_species !== prevProps.selected_species) {
      // Gets all the related species for the selected species.
      this.state.speciesRepository
        .getBycatchForSpecies(this.props.selected_species.code)
        .then(response => {
          this.setState({ related_species: response.data });
        });
    }
  }

  compareSpecies(a, b, sortingValue) {
    // only for undefined
    if (typeof a[sortingValue] === "undefined") return 1;
    if (typeof b[sortingValue] === "undefined") return 0;

    a = a[sortingValue];
    b = b[sortingValue];

    return a < b ? 1 : a > b ? -1 : 0;
  }

  // Filters the catch values to only include those corresponding to related species
  filterData() {
    return this.state.related_species.sort((a, b) =>
      this.compareSpecies(a, b, this.state.sortingValue)
    );
  }

  setSelectedSpeciesByCode(code) {
    if (window.event.ctrlKey) {
      this.state.speciesRepository.getSingleSpecies(code).then(response => {
        this.props.setSecondarySpecies(response.data[0]);
      });
    } else {
      this.state.speciesRepository.getSingleSpecies(code).then(response => {
        history.push(`/detail/${response.data[0].code}`)
      });
    }
  }


  render() {
    const catches = this.filterData();
    return (
      <div>
        {catches.length > 1 ? (
          <div>
            <Plot
              ref="bycatch_chart"
              className="bar-chart bycatch-chart"
              onClick={d => this.setSelectedSpeciesByCode(d.points[0].x)}
              data={[
                {
                  type: "bar",
                  x: catches.map(a => a.secondary_species),
                  y: catches.map(a => a.percent),
                  hovertemplate: "%{y} % Co-occurrence",
                  name: "",
                }
              ]}
              layout={{
                xaxis: { title: { text: "Species code or group" } },
                yaxis: { title: { text: "Percentage" } },
                title: `Percent co-occurrence in research survey trawls with ${this.props.selected_species.com_name}`,
                width: this.props.width,
                height: (this.props.height - 100) > 400 ? (this.props.height - 100) : 400
              }}
            />
            <SpeciesDetails selected_species={this.props.selected_species} />
          </div>
        ) : (
          <div className="no-data-available">No Data Avaliable</div>
        )}
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  { setSpecies, setSecondarySpecies }
)(TrawlChart);
