import React from "react";
import * as d3 from "d3";
import { ModalHeader, Modal, ModalBody } from "reactstrap";
import {
  setSpecies,
  setSecondarySpecies
} from "../../redux/actions/speciesAction";
import { connect } from "react-redux";
import { RepositoryFactory } from "../../repositories/RepositoryFactory";
import history from "../../history";
import OverviewDetailsChart from "../OverviewDetailsChart";

const mapStateToProps = state => {
  return {
    selectedSecondarySpecies: state.selectedSecondarySpecies,
    secondaryModeActive: state.secondaryModeActive
  };
};

class D3Component extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      speciesRepository: RepositoryFactory.get("species"),
      data: [],
      modal: false,
      infoType: "",
      infoTitle: "",
      infoAxis: "",
      scaleValue: 1
    };

    this.setSelectedSpeciesByCode = this.setSelectedSpeciesByCode.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
  }

  setSelectedSpeciesByCode(code) {
    if (window.event.ctrlKey) {
      this.state.speciesRepository.getSingleSpecies(code).then(response => {
        this.props.setSecondarySpecies(response.data[0]);
      });
    } else {
      this.state.speciesRepository.getSingleSpecies(code).then(response => {
        history.push(`/overview/${response.data[0].code}`);
      });
    }
  }

  toggleModal(d) {
    this.setState({ infoType: d.name });
    this.setState({ infoTitle: d.graphTitle ? d.graphTitle : `Overview for ${d.title}`});
    this.setState({ infoAxis: d.graphAxisLabel ? d.graphAxisLabel : d.title });
    this.setState({ scaleValue: d.graphScale ? d.graphScale : 1 });
    this.setState({ modal: !this.state.modal });
  }

  reorderSpecies(data) {
    const startCols = ["All species", "Elasmobranchs"];
    let reordered_data = [];
    let startColData = [];

    data.forEach(item => {
      // Checks if item in start cols
      if (startCols.indexOf(item.com_name) >= 0) {
        startColData.push(item);
      } else {
        reordered_data.push(item);
      }
    });

    // Sorts list and adds start columns on top of list
    reordered_data = reordered_data.sort((a, b) => a.com_name > b.com_name);
    startColData.forEach(item => {
      reordered_data.unshift(item);
    });

    return reordered_data;
  }

  componentDidMount() {
    this.props.toggle("Species Overview");
    this.state.speciesRepository.getSpeciesOverviewData().then(response => {
      this.setState({ data: response.data });
      d3.select("#" + this.props.id + "svg").remove();
      this.drawChart(response.data);
    });
  }

  componentDidUpdate(prevProps) {
    if (
      ((prevProps.height !== this.props.height ||
        prevProps.width !== this.props.width) &&
        this.state.data) ||
      ((prevProps.selectedSpecies !== this.props.selectedSpecies ||
        prevProps.selectedSecondarySpecies !==
          this.props.selectedSecondarySpecies ||
        prevProps.secondaryModeActive !== this.props.secondaryModeActive) &&
        this.state.data)
    ) {
      d3.select("#" + this.props.id + "svg").remove();
      this.drawChart(this.state.data);
    }
  }

  drawChart(raw_data) {
    let data = this.reorderSpecies(raw_data);

    var margin = { top: 70, right: 40, bottom: 20, left: 160 },
      width = this.props.width - margin.left - margin.right,
      height = this.props.height - margin.top - margin.bottom;

    var dimensions = [
      {
        name: "com_name",
        title: "Common Name",
        scale: d3.scale.ordinal().rangePoints([0, height]),
        type: String
      },
      {
        name: "avg_ann_weight",
        title: "Avg comm catch",
        graphTitle: "Overview for Avg commercial catch",
        graphAxisLabel: "Avg comm catch (x 1000 tonnes)",
        graphScale: 100,
        scale: d3.scale.linear().range([height, 0]),
        type: Number
      },
      {
        name: "no_catches",
        title: "Frequency In Surveys",
        graphTitle: "Overview for Frequency in surveys",
        graphAxisLabel: "No. of survey stations",
        scale: d3.scale.linear().range([height, 0]),
        type: Number
      },
      {
        name: "avg_biomass",
        title: "Avg survey biomass",
        graphTitle: "Overview for Avg survey biomass",
        graphAxisLabel: "Avg biomass (x 1000 tonnes)",
        graphScale: 1000,
        scale: d3.scale.linear().range([height, 0]),
        type: Number
      },
      {
        name: "long_biomass_trend",
        title: "Recent/Average Biomass",
        scale: d3.scale.linear().range([height, 0]),
        type: Number
      },
      {
        name: "recent_biomass_trend",
        title: "Recent/Initial Biomass",
        scale: d3.scale.linear().range([height, 0]),
        type: Number
      },
      {
        name: "avg_length",
        title: "Avg length",
        scale: d3.scale.linear().range([height, 0]),
        type: Number
      },
      {
        name: "l95",
        title: "L95 length",
        scale: d3.scale.linear().range([height, 0]),
        type: Number
      }
    ];

    var x = d3.scale
      .ordinal()
      .domain(
        dimensions.map(function(d) {
          return d.name;
        })
      )
      .rangePoints([0, width]);

    var line = d3.svg.line().defined(function(d) {
      return !isNaN(d[1]);
    });

    var yAxis = d3.svg.axis().orient("left");

    var svg = d3
      .select("#" + this.props.id)
      .append("svg")
      .attr("id", this.props.id + "svg")
      .attr("width", this.props.width + "px")
      .attr("height", this.props.height + "px")
      .append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    var dimension = svg
      .selectAll(".dimension")
      .data(dimensions)
      .enter()
      .append("g")
      .attr("class", "dimension")
      .attr("transform", function(d) {
        return "translate(" + x(d.name) + ")";
      });

    const setSelectedSpeciesByCode = this.setSelectedSpeciesByCode;
    const toggleModal = this.toggleModal;

    dimensions.forEach(function(dimension) {
      dimension.scale.domain(
        dimension.type === Number
          ? d3.extent(data, function(d) {
              return dimension.name === 'avg_ann_weight' ? +d[dimension.name] * 10 : +d[dimension.name];
            })
          : data.map(function(d) {
              return d[dimension.name];
            })
      );
    });

    let targetNames = ["long_biomass_trend", "recent_biomass_trend"];
    let targets = dimensions.filter(dim => targetNames.indexOf(dim.name) > -1);
    let values = [].concat.apply(
      [],
      targets.map(target => data.map(dat => dat[target.name]))
    );
    let minMaxVals = {
      min: Math.min(...values),
      max: Math.max(...values)
    };
    targets.map(target =>
      target.scale.domain([minMaxVals.min, minMaxVals.max])
    );

    svg
      .append("g")
      .attr("class", "foreground")
      .selectAll("path")
      .data(data)
      .enter()
      .append("path")
      .attr("d", draw);

    svg
      .append("g")
      .attr("class", "background")
      .selectAll("path")
      .data(data)
      .enter()
      .append("path")
      .attr("class", d => {
        if (
          this.props.selectedSpecies &&
          d.code === this.props.selectedSpecies.code
        ) {
          return "selected-species-path";
        } else if (
          this.props.selectedSecondarySpecies &&
          this.props.secondaryModeActive &&
          d.code === this.props.selectedSecondarySpecies.code
        ) {
          return "secondary-species-path";
        } else {
          return "";
        }
      })
      .attr("d", draw);

    dimension
      .append("g")
      .attr("class", "axis")
      .each(function(d) {
        d3.select(this).call(yAxis.scale(d.scale));
      })
      .append("text")
      .attr("class", d =>
        d.title !== "Common Name" ? "clickable-title title" : "title"
      )
      .attr("text-anchor", "end")
      .attr("transform", function(d) {
        if (d.title !== "Common Name") {
          return "rotate(20)";
        } else {
          return "";
        }
      })
      .attr("y", d => (d.title === "Common Name" ? -15 : -10))
      .text(function(d) {
        return d.title;
      });

    // Rebind the axis data to simplify mouseover.
    svg
      .select(".axis")
      .selectAll("text:not(.title)")
      .data(data, function(d) {
        return d.com_name || d;
      })
      .attr("class", d => {
        if (
          this.props.selectedSpecies &&
          d.code === this.props.selectedSpecies.code
        ) {
          return "selected-species-label label";
        } else if (
          this.props.selectedSecondarySpecies &&
          this.props.secondaryModeActive &&
          d.code === this.props.selectedSecondarySpecies.code
        ) {
          return "secondary-species-label label";
        } else {
          return "label";
        }
      });

    svg.selectAll(".foreground path").attr("class", "inactive");

    var projection = svg
      .selectAll(".axis text,.background path,.foreground path")
      .on("mouseover", mouseover)
      .on("mouseout", mouseout)
      .on("click", click);

    svg.selectAll(".clickable-title").on("click", toggleModal);

    function click(d) {
      setSelectedSpeciesByCode(d.code);
    }

    function mouseout() {
      projection.classed("inactive", () => true);
    }

    function mouseover(d) {
      svg.classed("active", true);
      projection.classed("inactive", function(p) {
        return p !== d;
      });
      projection
        .filter(function(p) {
          return p === d;
        })
        .each(moveToFront);
    }

    function moveToFront() {
      this.parentNode.appendChild(this);
    }

    function draw(d) {
      return line(
        dimensions.map(function(dimension) {
          return [x(dimension.name), dimension.scale(dimension.name === 'avg_ann_weight' ? d[dimension.name] * 10 : d[dimension.name])];
        })
      );
    }
  }

  render() {
    return (
      <Modal isOpen={this.state.modal} toggle={this.toggleModal} size="lg">
        <ModalHeader toggle={this.toggleModal}>
          {this.state.infoTitle}
        </ModalHeader>
        <ModalBody>
          <OverviewDetailsChart
            info={this.state.data}
            infoType={this.state.infoType}
            infoTitle={this.state.infoAxis}
            scaleValue={this.state.scaleValue}
          />
        </ModalBody>
      </Modal>
    );
  }
}

export default connect(
  mapStateToProps,
  { setSpecies, setSecondarySpecies }
)(D3Component);
