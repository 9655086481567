import React from "react";
import MapComponent from "./visualisations/MapComponent";
import { connect } from "react-redux";
import { setSecondarySpecies } from "../redux/actions/speciesAction";
import { RepositoryFactory } from "../repositories/RepositoryFactory";

const mapStateToProps = state => {
  return { selectedSecondarySpecies: state.selectedSecondarySpecies };
};

class MapArea extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      speciesRepository: RepositoryFactory.get("species"),
      speciesAbsData: [],
      speciesPosData: [],
      speciesSecondaryData: []
    };
  }

  /**
   * Updates the positions of species data when the props change.
   * @param {[*]} prevProps The previous props
   */
  componentDidUpdate(prevProps) {
    if (this.props.selected_species !== prevProps.selected_species) {
      if (!this.props.selected_species) {
        this.setState({ speciesPosData: [] });
        this.setState({ speciesAbsData: [] });
      } else {
        this.state.speciesRepository
          .getPostionsForSpecies(this.props.selected_species.code)
          .then(response => {
            this.setState({ speciesPosData: response.data });
          });
        this.state.speciesRepository
          .getAbsencesForSpecies(this.props.selected_species.code)
          .then(response => {
            this.setState({ speciesAbsData: response.data });
          });
      }
    }

    if (this.props.selectedSecondarySpecies !== prevProps.selectedSecondarySpecies) {
      this.state.speciesRepository
        .getPostionsForSpecies(this.props.selectedSecondarySpecies.code)
        .then(response => {
          this.setState({ speciesSecondaryData: response.data });
        });
    }
  }

  render() {
    return (
      <MapComponent
        speciesPosData={this.state.speciesPosData}
        map_width={this.props.map_width}
        speciesAbsData={this.state.speciesAbsData}
        speciesSecondaryData={this.state.speciesSecondaryData}
      />
    );
  }
}

export default connect(
  mapStateToProps,
  { setSecondarySpecies }
)(MapArea);
