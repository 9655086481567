import SpeciesRepository from "./SpeciesRepository"

// Add all repositories here to access from anywhere
const repositories = {
    species: SpeciesRepository
};


export const RepositoryFactory = {
    get: name => repositories[name]
};
