import React from "react";
import history from "../history";
import { Button, Table } from "reactstrap";
import { setSecondarySpecies } from "../redux/actions/speciesAction";
import { connect } from "react-redux";

class SectionInformation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      height: 0
    };

    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  getSectionInformation() {
    switch (this.props.sectionName) {
      case "Species Overview":
        return (
          <div>
            <p>
              This page shows a variety of characteristics for the 33 species
              and 2 groups well monitored by the middle depth trawl surveys
              carried out on the Chatham Rise since 1991. These surveys used
              standardised trawl gear on randomly generated stations stratified
              by area and depth (core area 200 m to 800 m). While the survey
              design was optimised for hoki, hake and ling, many other species
              were caught. Those considered to be well monitored based on
              distribution and coefficient of variation (c.v.) are listed (c.v.s
              on biomass estimates are shown under Biomass Trends). In addition
              to weight and size (length) of individual fish, data collected
              includes spawning condition and samples collected for age and diet
              determination.
            </p>

            <p>
              The left column lists the 33 species and 2 groups, the other
              columns show values representing various characteristics of those
              species. You can mouseover the species list or within the plot
              itself to highlight values for a single species. For example, to
              find out which species was caught on the most stations you can
              mouseover the top of the "Frequency in surveys" column to identify
              the species there, or mouseover a species in the list to see that
              species.
            </p>

            <p>
            Click on a species in the left column to select it (it will display bolded with a black line). Scroll up and down to see other species (blue line). The map on the left will show the distribution of catch events. Click on the tab to see scaled catch rates or catch presence and absence. To select a second species, click secondary species on the map tab, Ctrl click the second species, which will appear bolded in grey with a grey dashed line, and as white crosses on the map.
            </p>

            <Table>
              <thead>
                <tr>
                  <th>Column</th>
                  <th>Description</th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td>1</td>
                  <td>
                    The average estimated commercial catch (tonnes) for the
                    species, from 1974.
                  </td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>
                    Frequency in surveys: the number of stations where the
                    species was caught.
                  </td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>
                    Average calculated biomass for the survey time series
                    (tonnes).
                  </td>
                </tr>
                <tr>
                  <td>4</td>
                  <td>Biomass last 10 years average / average overall.</td>
                </tr>
                <tr>
                  <td>5</td>
                  <td>
                    Biomass last 10 years average / first 10 years average.
                  </td>
                </tr>
                <tr>
                  <td>6</td>
                  <td>Average scaled length for the time series.</td>
                </tr>
                <tr>
                  <td>7</td>
                  <td>
                    The L95% length (the maximum length reached by 95% of the
                    species, group or community)
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        );
      case "Species Details":
        return (
          <p>
            This shows more detailed information about the selected species. It
            includes plots showing the annual commercial catch of the chosen
            species from 1974 and species caught with the chosen species on
            surveys. It also shows basic taxonomic information and has links to
            the WoRMS (World Register of Marine Species), Fishbase website and
            the Fisheries New Zealand Field Guide to NZ Fishes for more
            information about the species.
          </p>
        );
      case "Biomass Trends":
        return (
          <div>
            <p>
              This tab shows the annual biomass indices for the well monitored
              species over the time series. Annual values represent the
              percentage difference between that year and the species average.
              The significance of the trend is tested against the average
              biomass for the period (allowing 3 degrees of freedom). This
              relative value allows species trends to be compared. The actual
              biomass values for the selected species are plotted on the species
              details tab.
            </p>
            <p>
              Each time series has been scaled to the average. Hover over a
              species to see a solid line showing the trend (relative to the
              mean) through the series. The shaded areas above and below show
              the 95% confidence intervals. Double click on the species to
              select that species, which will be shown with a solid black line,
              and mouse up and down over other species in the species list to
              compare trends.
            </p>
          </div>
        );
      case "Prey Plot":
        return (
          <div>
            <p>
              The Prey plot lists major species and species groups (left
              column), showing the percentage of different types of prey (other
              columns) that they eat. These percentages are based on a range of
              studies including the Chatham Rise trawl surveys. Given these
              species groupings are made on an ecological basis, they are
              different to those used for commercial catch data and the trawl
              survey time series, although some common species are the same.
            </p>

            <p>
              You can, much like the other tabs on this website, move the mouse
              over the species and groups in the left column to see what prey
              groups they feed on. You can also move the mouse over a prey
              column, to show the predators which do (or don't) eat that prey.
            </p>

            <p>The predator species and groups are:</p>

            <p>
              Arrow squid:<i>Notodarus spp.</i>
              <br />
              Baleen whales:<i> Mysticeti</i>
              <br />
              Basketwork eel: <i>Diastobranchus capensis</i>
              <br />
              Baxters dogfish: <i>Etmopterus baxteri</i>
              <br />
              Ben fish deep: Deep water benthic fish
              <br />
              Ben fish shal: Shallow water benthic fish
              <br />
              Benthic Carniv: Benthic carnivorous fish
              <br />
              Black oreo: <i>Allocyttus niger</i>
              <br />
              Bollons rattail: <i>Coelorinchus bollonsi</i>
              <br />
              Carniv Zoo: Carnivrous zooplankton
              <br />
              Cephalopod other: Other squids and octopuses
              <br />
              Cetacean other: Other whales and dolphins
              <br />
              Dem fish pisc: Demersal fish-eating fish
              <br />
              Deposit Feeder: Scavenger
              <br />
              DinoFlag: <i>Dinoflagellates</i>
              <br />
              Elasmobranch invert: Sharks and rays which eat invertebrates
              <br />
              Elasmobranch pisc: Sharks and rays which eat fish
              <br />
              Epiben fish deep: Deep water epibenthic fish
              <br />
              Epiben fish shal: Shallow water epibenthic fish
              <br />
              Filter Other: Other filter feeders
              <br />
              Gelat Zoo: Gelatinous zooplankton (planktonic jellyfish, slaps,
              etc)
              <br />
              Ghost shark: <i>Chimaeras</i>
              <br />
              Hake: <i>Merluccius australis</i>
              <br />
              Hoki: <i>Macruronus novaezelandiae</i>
              <br />
              Invert comm herb: Common herbivorous invertebrates
              <br />
              Invert comm scav: Common scavenging invertebrates
              <br />
              Javelinfish: <i>Lepidorhynchus denticulatus</i>
              <br />
              Ling: <i>Genypterus blacodes</i>
              <br />
              Lookdown dory: <i>Cyttus traversi</i>
              <br />
              Mackerels: Jack mackerels
              <br />
              Meiobenthos: small benthic invertebrates
              <br />
              MesoZoo: amphipods, euphausids, etc
              <br />
              MicroZoo: Microzooplankton
              <br />
              Orange roughy: <i>Hoplostethus atlanticus</i>
              <br />
              Pelagic fish lge: Large pelagic fish (tunas, sunfish, oarfish,
              etc)
              <br />
              Pelagic fish med: Medium sized pelagic fish (baracoutta,
              frostfish, rays bream, etc)
              <br />
              Pelagic fish sml: Small pelagic fish (saury, garfish, sprats, etc)
              <br />
              Pinniped: Seals
              <br />
              Reef fish: Blue cod, butterfish, blue moki, etc
              <br />
              Rock lobster: Jasus spp.
              <br />
              Seabird: Seabirds
              <br />
              Seaperch: <i>Helicolenus spp.</i>
              <br />
              Shovelnosed dogfish: <i>Deania calcea</i>
              <br />
              Smooth oreo: <i>Pseudocyttus maculatus</i>
              <br />
              Spiny dogfish: Southern and northern spiny dogfish
              <br />
              Warehou: Silver, white and common warehou
              <br />
            </p>
          </div>
        );
      case "Map":
        return <p />;
      default:
        return <p />;
    }
  }

  render() {
    return (
      <div>
        <div className="section-info-header">
          <h4 className="pl-4">{this.props.sectionName}</h4>
          <Button
            outline
            color="primary"
            className="mr-4"
            onClick={() => {
              history.push(`/${window.location.pathname.split("/")[1]}`);
              this.props.setSecondarySpecies({});
            }}
          >
            Clear Species
          </Button>
        </div>
        <div
          className="section-info-area"
          style={{
            maxHeight: this.state.height - 370,
            minHeight: this.state.height - 370
          }}
        >
          <div className="pl-4 pt-3 pr-4">{this.getSectionInformation()}</div>
        </div>
      </div>
    );
  }
}

export default connect(
  null,
  { setSecondarySpecies }
)(SectionInformation);
