import React from "react";
import { TabContent, TabPane, Nav, NavItem, NavLink, Button } from "reactstrap";
import classnames from "classnames";
import AnalysisHeader from "./AnalysisHeader";
import TrawlChart from "./TrawlChart";
import SpeciesOverview from "./visualisations/SpeciesOverview";
import PreyChart from "./visualisations/PreyChart";
import BiomassTrends from "./visualisations/BiomassTrends";
import BiomassTrendsGrid from "./visualisations/BiomassTrendsGrid";
import { Link, Route } from "react-router-dom";
import Measure from "react-measure";
import AnnualCatchesChart from "./AnnualCatchesChart";

export default class AnalysisPanal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      alertActive: true,
      height: 0,
      width: 0,
      navHeight: 0,
      headerHeight: 0,
      biomassTrendsSeparate: false,
      hoverSpecies: "",
      hoverAvgBiomass: 0
    };

    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.setHoverSpeciesBiomass = this.setHoverSpeciesBiomass.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({
      width: this.getContentWidth(),
      height: this.getContentHeight()
    });
  }

  toggle(name) {
    this.props.setCurrentSection(name);
  }

  setHoverSpeciesBiomass(species, biomass) {
    this.setState({hoverSpecies: species});
    this.setState({hoverAvgBiomass: biomass});
  }

  getContentHeight() {
    const totalHeight = window.innerHeight;
    const headerHeight =
      this.refs.analysis_header.clientHeight + this.state.navHeight;

    return totalHeight - headerHeight - 40;
  }

  getContentWidth() {
    return this.refs.analysis_panel.clientWidth;
  }

  render() {
    return (
      <div className="analysis-panel" ref="analysis_panel">
        <div ref="analysis_header">
          <AnalysisHeader
            selected_species={
              this.props.selected_species ? this.props.selected_species : {}
            }
          />
        </div>
        <Measure
          bounds
          onResize={contentRect => {
            this.setState({
              height:
                window.innerHeight -
                (this.refs.analysis_header.clientHeight +
                  contentRect.bounds.height) -
                40
            });
          }}
        >
          {({ measureRef }) => (
            <div ref={measureRef}>
              <Nav tabs>
                <NavItem>
                  <Link
                    to={`/overview${
                      this.props.selected_species
                        ? "/" + this.props.selected_species.code
                        : ""
                    }`}
                    onClick={() => {
                      this.toggle("Species Overview");
                    }}
                    className={
                      classnames({
                        active: this.props.activeGraph === "overview"
                      }) + " nav-link"
                    }
                  >
                    Species Overview
                  </Link>
                </NavItem>
                <NavItem>
                {this.props.selected_species && this.props.selected_species.code ? (
                  <Link
                    to={`/detail${
                      this.props.selected_species
                        ? "/" + this.props.selected_species.code
                        : ""
                    }`}
                    onClick={() => {
                      this.toggle("Species Details");
                    }}
                    className={
                      classnames({
                        active: this.props.activeGraph === "detail"
                      }) + " nav-link"
                    }
                  >
                    Species Details
                  </Link>) : (
                      <NavLink
                      className="disabled-tab"
                    >
                      Species Details
                    </NavLink>
                  )}
                </NavItem>
                <NavItem>
                  <Link
                    to={`/biomass${
                      this.props.selected_species
                        ? "/" + this.props.selected_species.code
                        : ""
                    }`}
                    onClick={() => {
                      this.toggle("Biomass Trends");
                    }}
                    className={
                      classnames({
                        active: this.props.activeGraph === "biomass"
                      }) + " nav-link"
                    }
                  >
                    Biomass Trends
                  </Link>
                </NavItem>

                <NavItem>
                  <Link
                    to={`/prey${
                      this.props.selected_species
                        ? "/" + this.props.selected_species.code
                        : ""
                    }`}
                    onClick={() => {
                      this.toggle("Prey Plot");
                    }}
                    className={
                      classnames({
                        active: this.props.activeGraph === "prey"
                      }) + " nav-link"
                    }
                  >
                    Prey
                  </Link>
                </NavItem>
              </Nav>
            </div>
          )}
        </Measure>

        <TabContent activeTab={this.props.activeGraph}>
          {this.props.selected_species && this.props.selected_species.code ? (
            <TabPane tabId="detail">
              <Route
                path="/detail/:species_code"
                render={props => (
                  <div>
                    <AnnualCatchesChart
                      {...props}
                      toggle={section => this.toggle(section)}
                      height={(this.state.height + 40) / 2}
                      width={this.state.width}
                    />
                    <TrawlChart
                      {...props}
                      toggle={section => this.toggle(section)}
                      height={(this.state.height + 40) / 2}
                      width={this.state.width}
                    />
                  </div>
                )}
              />
            </TabPane>
          ) : (
            <TabPane tabId="detail" >
          
            </TabPane>
          )}
          <TabPane tabId="overview">
            <Route
              path="/overview"
              render={() => (
                <div>
                  <SpeciesOverview
                    id="d3-example"
                    className="d3-chart"
                    selectedSpecies={this.props.selected_species}
                    toggle={section => this.toggle(section)}
                    closeAlert={() => this.setState({ alertActive: false })}
                    height={this.state.height}
                    width={this.state.width}
                  />
                  <div id="d3-example" className="d3-chart" />
                </div>
              )}
            />
          </TabPane>
          <TabPane tabId="biomass">
            <Route
              path="/biomass"
              render={() => (
                <div>
                  {this.state.biomassTrendsSeparate ? (
                    <div className="biomass-container">
                     <div className="biomass-button-container">
                      <div/>
                        <Button
                          className="biomass-toogle-button"
                          outline
            color="primary"
                          onClick={() =>
                            this.setState({ biomassTrendsSeparate: false })
                          }
                        >
                          Switch to overview mode
                        </Button>
                      </div>
                      <BiomassTrendsGrid
                        id="biomass-chart"
                        className="d3-chart"
                        selectedSpecies={this.props.selected_species}
                        toggle={section => this.toggle(section)}
                        closeAlert={() => this.setState({ alertActive: false })}
                        height={this.state.height}
                        width={this.state.width}
                      />
                    </div>
                  ) : (
                    <div className="biomass-container">
                       <div className="biomass-button-container">
                       <p>Average Biomass for {this.state.hoverSpecies}: <strong>{this.state.hoverAvgBiomass} kg</strong></p>
                        <Button
                          className="biomass-toogle-button"
                          outline
            color="primary"
                          onClick={() =>
                            this.setState({ biomassTrendsSeparate: true })
                          }
                        >
                          Switch to separate mode
                        </Button>
                      </div>
                      <BiomassTrends
                        id="biomass-chart"
                        className="d3-chart"
                        selectedSpecies={this.props.selected_species}
                        setBiomassDetails={this.setHoverSpeciesBiomass}
                        toggle={section => this.toggle(section)}
                        closeAlert={() => this.setState({ alertActive: false })}
                        height={this.state.height - 100}
                        width={this.state.width}
                      />
                      <div id="biomass-chart" className="d3-chart biomass-chart" />
                     
                    </div>
                  )}
                </div>
              )}
            />
          </TabPane>
          <TabPane tabId="prey">
            <Route
              path="/prey"
              render={() => (
                <div>
                  <PreyChart
                    id="prey-chart"
                    selectedSpecies={this.props.selected_species}
                    toggle={section => this.toggle(section)}
                    className="d3-chart"
                    closeAlert={() => this.setState({ alertActive: false })}
                    height={this.state.height}
                    width={this.state.width}
                  />

                  <div id="prey-chart" className="d3-chart" />
                </div>
              )}
            />
          </TabPane>
        </TabContent>
      </div>
    );
  }
}
