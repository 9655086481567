import React from "react";
import * as d3 from "d3";
import {
  setSpecies,
  setSecondarySpecies
} from "../../redux/actions/speciesAction";
import { connect } from "react-redux";
import { RepositoryFactory } from "../../repositories/RepositoryFactory";
import history from "../../history";

const mapStateToProps = state => {
  return {
    selectedSecondarySpecies: state.selectedSecondarySpecies,
    secondaryModeActive: state.secondaryModeActive
  };
};

class BiomassTrends extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      speciesRepository: RepositoryFactory.get("species")
    };

    this.setSelectedSpeciesByCode = this.setSelectedSpeciesByCode.bind(this);
  }

  setSelectedSpeciesByCode(code) {
    if (window.event.ctrlKey) {
      this.state.speciesRepository.getSingleSpecies(code).then(response => {
        this.props.setSecondarySpecies(response.data[0]);
      });
    } else {
      this.state.speciesRepository.getSingleSpecies(code).then(response => {
        if (response.data[0].code) {
          history.push(`/biomass/${response.data[0].code}`);
        }
      });
    }
  }

  componentDidMount() {
    this.props.toggle("Biomass Trends");
    this.state.speciesRepository
      .getSpeciesBiomass()
      .then(biomassDataResponse => {
        this.state.speciesRepository
          .getSpeciesBiomassYears()
          .then(biomassYearsResponse => {
            this.setState({
              biomass_data: biomassDataResponse.data,
              year_data: biomassYearsResponse.data
            });
            this.drawChart(biomassDataResponse.data, biomassYearsResponse.data);
          });
      });
  }

  componentDidUpdate(prevProps) {
    if (
      (prevProps.height !== this.props.height ||
        prevProps.width !== this.props.width ||
        prevProps.selectedSpecies !== this.props.selectedSpecies ||
        prevProps.selectedSecondarySpecies !==
          this.props.selectedSecondarySpecies ||
        prevProps.secondaryModeActive !== this.props.secondaryModeActive) &&
      this.state.year_data &&
      this.state.biomass_data
    ) {
      d3.select("#" + this.props.id + "svg").remove();
      this.drawChart(this.state.biomass_data, this.state.year_data);
    }
  }

  drawChart(data, years) {
    var margin = { top: 30, right: 40, bottom: 20, left: 80 },
      width = this.props.width - margin.left - margin.right,
      height = this.props.height - margin.top - margin.bottom;

    const MAX_Y_VALUE = 4.5;

    // Dimensions => the different columns in the chart.
    // Adding all the years to the dimensions
    var dimensions = years.map(year => {
      return {
        name: year.year,
        // Scale linear means it will constuct a linear scale between the DEFAULT range [0, 1] or specifed as below.
        // For this case it is scaling between 0 and the height of the chart.
        scale: d3.scale.linear().range([height, 0]),
        type: Number
      };
    });

    // Adds the species names for the dimensions for the first column.
    dimensions.unshift({
      name: "species",
      // Scale ordinal is more or less used for discrete values but are ordered, typically non numeric concepts.
      // This case works since the names do not need to be numeric.
      scale: d3.scale.ordinal().rangePoints([0, height]),
      type: String
    });

    // Another ordinal scale which is being used for the columns of the chart. So for all the heading names and their corresponding axis.
    // This can be used later on to get the x value of points in the graph accessing by x(name of col)
    var x = d3.scale
      .ordinal()

      // Setting the domain to a specified set of values in this case it is the name of the columns along the top of the chart.
      .domain(
        dimensions.map(function(d) {
          return d.name;
        })
      )

      // Between 0 and the maximum width of the chart.
      .rangePoints([0, width]);

    // Sets the line accessor function of where the line is defined.
    // If defined it sets the new accessor function and returns the line.
    // This is useful when dealing with missing data.
    // ie ignoring y values which are not a number (undefined) like the one below.
    var line = d3.svg.line().defined(function(d) {
      return !isNaN(d[1]);
    });

    // Sets the new default axis which is oriented as a vertical axis with ticks left of the domain path.
    var yAxis = d3.svg.axis().orient("left");

    var svg = d3
      // selects the html element to deal with
      .select("#" + this.props.id)

      // creates and appends a new svg element to the above selected element
      .append("svg")
      .attr("id", this.props.id + "svg")
      // Sets the width of the above svg element
      .attr("width", this.props.width + "px")
      // Sets the height of the above svg element
      .attr("height", this.props.height + "px")
      // Appends a g element to the svg element?
      // A g element is a container used to group other svg elements.
      .append("g")
      // Applies transformation and translation to the element (what element - main element - the one with the id)?
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    var dimension = svg
      // Selects all elements with the class 'dimension'
      // (But the class is set below???)
      .selectAll(".dimension")
      // Sets the data of the element to the dimensions array?
      // Joins the specified array or data with the current selection.
      .data(dimensions)
      // Returns the 'enter' selection which are placeholder nodes
      // so this means since the set of .dimensions elements would be empty
      // it would create a .dimension element for each element in the dimensions data array.
      .enter()

      // here there is a g element created with the dimension class and the transformed the to position of each data element in the dimensions array.
      // This might be creating the headings at the top of the chart??
      .append("g")
      .attr("class", "dimension")
      .attr("transform", function(d) {
        return "translate(" + x(d.name) + ")";
      });

    // Sets local variables here to the props to deal with 'this' problems.
    const setSelectedSpeciesByCode = this.setSelectedSpeciesByCode;
    const setBiomassDetails = this.props.setBiomassDetails;

    var area = d3.svg
      .area()
      .x(function(d) {
        return d.x;
      })
      .y0(function(d) {
        return d.y0;
      })
      .y1(function(d) {
        return d.y1;
      });

    // loops though each element in the dimensions array.
    dimensions.forEach(function(dimension) {
      // sets the dimension scale input domain (x-values)
      dimension.scale.domain(
        dimension.type === Number
          ? // If the dimension type is one of the numerical values it finds the minimum and maximum values of the particular dimension.
            // In this case it looks at the min/max values of each years biomass.
            // Syntax extent(array[, accessor])
            [
              d3.min(data, function(d) {
                return Math.min(
                  d[dimension.name]["n_biomass"],
                  d[dimension.name]["n_lbound"],
                  d[dimension.name]["n_ubound"]
                );
              }),
              MAX_Y_VALUE
            ]
          : // For the labels it returns the label value
            // Not sure where this fits in or how this relates to domain though.???
            data
              .map(function(d) {
                return d[dimension.name];
              })
              .sort()
      );
    });

    // Same as above but draws a path with class of foreground which has different css rules.
    svg
      .append("g")
      .attr("class", "foreground")
      .selectAll("path")
      .data(data)
      .enter()
      .append("path")
      .attr("d", draw);

    // Appends a g element to the svg
    svg
      .append("g")
      // Sets the class name of the line to background
      .attr("class", "background")
      // Selects all the elements of type path
      .selectAll("path")
      // Sets the data to be used
      .data(data)
      // Enters each data value
      .enter()
      // Appends a path element to the g element of path collection above.
      .append("path")
      .attr("class", d => {
        if (
          this.props.selectedSpecies &&
          d.species === this.props.selectedSpecies.code
        ) {
          return "selected-species-path";
        } else if (
          this.props.selectedSecondarySpecies &&
          this.props.secondaryModeActive &&
          d.species === this.props.selectedSecondarySpecies.code
        ) {
          return "secondary-species-path";
        } else {
          return "";
        }
      })

      // adds an attibute to the path of d, like this
      /**<path d="
            M 213.1,6.7
            c -32.4-14.4-73.7,0-88.1,30.6
            C 110.6,4.9,67.5-9.5,36.9,6.7
            C 2.8,22.9-13.4,62.4,13.5,110.9
            C 33.3,145.1,67.5,170.3,125,217
            c 59.3-46.7,93.5-71.9,111.5-106.1
            C 263.4,64.2,247.2,22.9,213.1,6.7
            z" /> */
      // This describes what the path draws
      // M = pick up pen and move to this place at these exact coords
      // m = same as above but move to these coords relative to current pos
      // (lower case in general means relative while capital is absolute)
      // So L/l = line, H/h = horizontal (only horizontal coord), V/v = vertical(only vertical coord)
      // Z = move the pen bask to the start which closes the path.
      // C = bezier curves!!
      // See https://css-tricks.com/svg-path-syntax-illustrated-guide/ for more
      .attr("d", draw);

    svg
      .append("svg")
      .style("height", height - margin.top)
      .append("g")
      .attr("class", "areaforeground")
      // Selects all the paths in the outer element g
      // This will be initially empty and will be filled using the path below
      .selectAll("path")

      // This sets the data for all the paths
      // Binding this data to the empty selection array of paths defined above.
      .data(formatData())

      // This must be called before adding elements to the current selection
      // it takes the selection returned by data() and prepares it for adding elements
      .enter()

      // Returns the path as the current selection!!
      .append("path")
      .attr("d", areaFunction);

    dimension
      .append("g")
      .attr("class", "axis")
      .each(function(d) {
        if (d.name === 1992 || d.name === "species") {
          d3.select(this).call(yAxis.scale(d.scale));
        } else {
          d3.select(this).call(yAxis.scale(d.scale).tickFormat(""));
        }
      })
      .append("text")
      .attr("class", "title")
      .attr("text-anchor", "middle")
      .attr("y", -9)
      .text(function(d) {
        return d.name;
      });

    // Rebind the axis data to simplify mouseover.
    svg
      .select(".axis")
      .selectAll("text:not(.title)")
      .data(data, function(d) {
        return d.species || d;
      })
      .attr("class", d => {
        if (
          this.props.selectedSpecies &&
          d.species === this.props.selectedSpecies.code
        ) {
          return "selected-species-label label";
        } else if (
          this.props.selectedSecondarySpecies &&
          this.props.secondaryModeActive &&
          d.species === this.props.selectedSecondarySpecies.code
        ) {
          return "secondary-species-label label";
        } else {
          return "label";
        }
      });

    svg.selectAll(".foreground path").attr("class", "inactive");
    svg.selectAll(".areaforeground path").attr("class", "inactive");

    var projection = svg
      .selectAll(
        ".axis text,.background path,.foreground path, .areaforeground path"
      )
      .on("click", click);

    svg
      .selectAll(".foreground path, .label")
      .on("mouseout", mouseout)
      .on("mouseover", mouseover);

    function click(d) {
      setSelectedSpeciesByCode(d.species);
    }

    function areaFunction(d) {
      return area(d.slice(1, d.length));
    }

    /**
     * Sets all items to inactive
     */
    function mouseout() {
      projection.classed("inactive", () => true);
    }

    function mouseover(d) {
      // Sets the current selected item to active
      svg.classed("active", true);
      setBiomassDetails(d["species"], findAvgBiomass(d));

      // Set all the other elements which are not selected currently to inactive
      // Also removes the inactive from the active element!!!
      projection.classed("inactive", function(p) {
        if (p["species"]) {
          return p !== d;
        } else {
          return p[0] !== d["species"];
        }
      });

      // Moves the current projection to the front
      projection
        .filter(function(p) {
          if (p["species"]) {
            return p === d;
          } else {
            return p[0] === d["species"];
          }
        })
        .each(moveToFront);
    }

    function moveToFront() {
      this.parentNode.appendChild(this);
    }

    function draw(d) {
      return line(
        dimensions.map(function(dimension) {
          return [
            x(dimension.name),
            dimension.scale(d[dimension.name]["n_biomass"])
          ];
        })
      );
    }

    // Where d is the instance of the data ie the species in question
    function formatData() {
      let formattedData = data.map(d =>
        dimensions.map(function(dimension) {
          if (dimension.name === "species") {
            return d[dimension.name];
          }

          return {
            x: x(dimension.name),
            y0: dimension.scale(d[dimension.name]["n_lbound"]),
            y1: dimension.scale(d[dimension.name]["n_ubound"])
          };
        })
      );
      return formattedData;
    }

    function findAvgBiomass(biomass_data) {
      return Object.keys(biomass_data)[0]
        ? biomass_data[Object.keys(biomass_data)[0]]["avg_biomass"]
        : "Not Available";
    }
  }

  render() {
    return <div />;
  }
}

export default connect(
  mapStateToProps,
  { setSpecies, setSecondarySpecies }
)(BiomassTrends);
