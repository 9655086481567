import React from "react";

export default class SpeciesDetails extends React.Component {
  render() {
    return (
      <div className="species-details">
      <div className="row wrap no-padding no-margin">
        <div className="col detail-block">
          <strong>Species ID:</strong>
          <p>{this.props.selected_species.id}</p>
        </div>

          <div className="col detail-block">
            <strong>Species Code</strong>
            <p>{this.props.selected_species.code}</p>
          </div>

          <div className="col detail-block fixed-width">
            <strong>Common Name</strong>
            <p>{this.props.selected_species.com_name}</p>
          </div>

          <div className="col detail-block fixed-width">
            <strong>Common Family Name</strong>
            <p>{this.props.selected_species.fam_com}</p>
          </div>

          <div className="col detail-block fixed-width">
            <strong>Scientific Family Name</strong>
            <p><i>{this.props.selected_species.fam_sci}</i></p>
          </div>

          <div className="col detail-block">
            <strong>WORMS</strong>
            {this.props.selected_species.aphia ? (
              <a
                target="blank"
                href={
                  "http://www.marinespecies.org/aphia.php?p=taxdetails&id=" +
                  this.props.selected_species.aphia +
                  "#images"
                }
              >
                <p>Link to WoRMS</p>
              </a>
            ) : (
              <p>No Link</p>
            )}
          </div>


          <div className="col detail-block">
            <strong>NIWA</strong>
            {this.props.selected_species.niwa_id ? (
              <a
                target="blank"
                href={
                  "https://prs.niwa.co.nz/ReportServlet?report.url=http://docs.external.niwa.co.nz/tad/prod/reports/marine_fish_idls.jasper&xml.url=https://tad.niwa.co.nz/api/taxon/" +
                  this.props.selected_species.niwa_id
                  + "/18&xpath.path=/taxa/taxon&format=pdf"
                }
              >
                <p>FNZ field guide NZ Fishes</p>
              </a>
            ) : (
              <p>No Link</p>
            )}
          </div>


          <div className="col detail-block">
            <strong>FISHBASE</strong>
            {this.props.selected_species.niwa_id ? (
              <a
                target="blank"
                href={
                  "http://fishbase.sinica.edu.tw/Summary/SpeciesSummary.php?ID=" +
                  this.props.selected_species.fishbase_id
                }
              >
                <p>Link to Fishbase</p>
              </a>
            ) : (
              <p>No Link</p>
            )}
          </div>
        </div>
      </div>
    );
  }
}
