import React from 'react';
import './assets/css/style.scss';
import Dashboard from "./components/Dashboard"
import { connect } from 'react-redux'
import { Router, Switch, Route } from "react-router-dom"
import { setSpecies } from './redux/actions/speciesAction'
import history from './history';

const mapStateToProps = state => {
  return { selected_species: state.selectedSpecies };
};

class App extends React.Component {
  render() {
    return (
      <div className="App">
      <Router  history={history}>
        <Switch>
          <Route path={`/`} exact component={Dashboard} />
         
          <Route path={`/:graph_type/:species_code`} component={Dashboard} />
          <Route path={`/:graph_type`} component={Dashboard} />
          
        </Switch>
        </Router>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  {setSpecies}
)(App)