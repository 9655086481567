import React from "react";

import { Map } from "react-leaflet";
import * as L from "leaflet";
import * as Proj from "proj4leaflet";
import * as d3ColorScale from "d3-scale-chromatic";

import contourLayer from "./chat_250_contours.json";
import studyAreaLayer from "./chat_area.json";

import { connect } from "react-redux";
import { setSecondarySpecies, setSecondarySpeciesMode } from "../../redux/actions/speciesAction";

var mapBounds = new L.LatLngBounds([
  // baselayer bounds
  [-46.1246208, 172.1504288],
  [-41.3066612, 185.8114035]
]);

mapBounds = new L.LatLngBounds([
  // slightly smaller bounds based on survey area
  [-44.801415, 172.4756903],
  [-42.7090777, 185.4861423]
]);

var imageUrl = "/hillshade.jpg";
var imageBounds = L.bounds([
  [6070389.6971817966550589, -4360604.816736196167767], // 172.1504288, -46.1246208 bottom-left = south-west
  [7219758.322580099105835, -3801268.156304975040257] // -174.1885965, -41.3066612 top-right = north-east
]);

const overlay = Proj.imageOverlay(imageUrl, imageBounds);
const markerStyle = {
  fill: false,
  weight: 1,
  radius: 2,
  opacity: 1,
  color: "red"
};
const makeLayer = (obj, style = markerStyle) =>
  L.circleMarker([obj.lat_s, obj.lon_s], style);

var crossIcon = L.icon({
  iconUrl: "/marker_cross.png",
  iconSize: [5, 5] // size of the icon
});

const makeSecondaryLayer = obj =>
  L.marker([obj.lat_s, obj.lon_s], { icon: crossIcon });

const mapStateToProps = state => {
  return { selectedSecondarySpecies: state.selectedSecondarySpecies };
};

class MapComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      markers: {
        Catches: L.featureGroup(),
        "Scaled catches": L.featureGroup(),
        "Catch & Absence": L.featureGroup(),
        "Secondary species": L.featureGroup()
      },
      layers: {}
    };
  }

  getColor(weight, maxWeight) {
    let n = ((weight / maxWeight) * 0.7) + 0.3
    return d3ColorScale.interpolateReds(n)
  }

  iterMarkers(func) {
    for (var key in this.state.markers) {
      func(this.state.markers[key]);
    }
  }

  /**
   * Sets the secondary species position data for the specified species
   * @param {Object} species
   */
  setSecondarySpeciesData(species) {
    // TODO: connect this method with search box on map.
    this.props.setSecondarySpecies(species);
  }

  componentDidMount() {
    let map = this.refs.map.leafletElement;

    let crs = new Proj.CRS(
      "EPSG:3994",
      "+proj=merc +lon_0=100 +lat_ts=-41 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs",
      {
        //resolutions: [1149368.625398302 / this.props.map_width] // use for baselayer bounds
        resolutions: [1094636.786093621 / this.props.map_width] // use for survey area bounds
      }
    );
    map.options.crs = crs;

    let layers = {
      Contours: L.Proj.geoJson(contourLayer, {
        style: { color: " #ddd", weight: 1, opacity: 0.5 }
      }),
      "Study Area": L.Proj.geoJson(studyAreaLayer, {
        style: {
          fillColor: "#ff000080",
          color: "#00000000",
          fillOpacity: 0.8
        }
      })
    };

    this.setState({ layers });
    let controlLayers = L.control.layers(this.state.markers, layers);
    controlLayers.addTo(map);
    map.on('baselayerchange' , (e) => {
      if (e.name === "Secondary species") {
        this.props.setSecondarySpeciesMode(true)
      } else {
        this.props.setSecondarySpeciesMode(false)
      }
    })

    this.state.markers.Catches.addTo(map);

    map.on("overlayadd", () => {
      this.state.layers["Study Area"].bringToFront();
      this.state.layers["Contours"].bringToFront();
      this.iterMarkers(layerGroup => layerGroup.bringToFront());
    });
  }

  componentDidUpdate(prevProps) {
    let map = this.refs.map.leafletElement;

    if (this.props.map_width !== prevProps.map_width) {
      map.invalidateSize();
      let crs = new Proj.CRS(
        "EPSG:3994",
        "+proj=merc +lon_0=100 +lat_ts=-41 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs",
        {
          //resolutions: [1149368.625398302 / this.props.map_width] // use for baselayer bounds
          resolutions: [1094636.786093621 / this.props.map_width] // use for survey area bounds
        }
      );
      map.options.crs = crs;

      map.removeLayer(overlay);

      this.iterMarkers(layerGroup => {
        if (map.hasLayer(layerGroup)) {
          map.removeLayer(layerGroup);
          map.addLayer(layerGroup);
        }
      });

      overlay.addTo(map);
      map.fitBounds(mapBounds);
    }

    if (
      this.props.speciesPosData !== prevProps.speciesPosData ||
      this.props.speciesAbsData !== prevProps.speciesAbsData ||
      this.props.speciesSecondaryData !== prevProps.speciesSecondaryData
    ) {
      const markers = this.state.markers;
      this.iterMarkers(layerGroup => layerGroup.clearLayers());

      const maxWeight = Math.max.apply(
        Math,
        this.props.speciesPosData.map(obj => obj.weight)
      );

      this.props.speciesAbsData.forEach(obj =>
        markers["Catch & Absence"].addLayer(
          makeLayer(obj, { ...markerStyle, color: "black" })
        )
      );

      this.props.speciesSecondaryData.forEach(obj =>
        markers["Secondary species"].addLayer(makeSecondaryLayer(obj))
      );

      this.props.speciesPosData.forEach(obj => {
        markers["Catches"].addLayer(makeLayer(obj));
        markers["Catch & Absence"].addLayer(makeLayer(obj));
        markers["Scaled catches"].addLayer(
          makeLayer(obj, {
            ...markerStyle,
            radius: (2 * 3 * obj.weight) / maxWeight,
            color: this.getColor(obj.weight, maxWeight)
          })
        );
        markers["Secondary species"].addLayer(makeLayer(obj));
      });

      this.iterMarkers(layer => layer.bringToFront());
    }
  }

  render() {
    return (
      <Map
        preferCanvas={true}
        ref="map"
        zoomControl={false}
        style={{
          width: this.props.map_width,
          height: this.props.map_width / 2.053353659
        }}
        maxBounds={mapBounds}
        dragging={false}
      />
    );
  }
}

export default connect(
  mapStateToProps,
  { setSecondarySpecies, setSecondarySpeciesMode }
)(MapComponent);
