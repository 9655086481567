import React from "react";
import AnalysisPanel from "./AnalysisPanel";
import MapArea from "./MapArea";
import SectionInformation from "./SectionInformation";
import { connect } from "react-redux";
import { setSpecies } from "../redux/actions/speciesAction";
import { RepositoryFactory } from "../repositories/RepositoryFactory";
import history from "../history";

const mapStateToProps = state => {
  return { selected_species: state.selectedSpecies };
};

class Dashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      speciesRepository: RepositoryFactory.get("species"),
      species: [],
      currentSection: "Species Overview",
      map_width: 0
    };

    this.setSelected = this.setSelected.bind(this);
    this.setSelectedByCode = this.setSelected.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    this.state.speciesRepository.getSpecies().then(response => {
      this.setState({ species: response.data });
      this.setSelectedSpeciesByCode(this.props.match.params.species_code);

      if ((this.props.match.params.species_code ===  undefined) && (this.props.match.params.graph_type === undefined)) {
        history.push(`/overview`)
      }
    });
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ map_width: this.getContentWidth() });
  }

  getContentWidth() {
    // This really isn't an ideal way of calculating the width, because we're hard-coding
    // the LHS aspect ratio to 33% for large screens and 100% for smaller screens.
    // We're doing this because `left_side.innerWidth` and `left_side.offsetWidth` often
    // return a value a few pixels too small, making the map area too small. I believe
    // something else re-sizes the left column (maybe flex?) **after** the resize event
    // has finished.
    const width = window.innerWidth;
    const lhsWidth = this.refs.left_side.clientWidth;
    const value = lhsWidth < (width / 2) ? (width / 3).toFixed(0) : width;
    return parseFloat(value)
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.match.params.species_code !== this.props.match.params.species_code &&
      (!this.props.selected_species ||
      this.props.selected_species.code !== this.props.match.params.species_code)
    ) {
      this.setSelectedSpeciesByCode(this.props.match.params.species_code);
    }
  }

  setSelectedSpeciesByCode(code) {
    this.state.speciesRepository
      .getSingleSpecies(code)
      .then(response => {
        this.props.setSpecies(response.data[0]);
      })
      .catch(() => {this.props.setSpecies(undefined)});
  }

  /**
   * Sets the selected speces to the given species.
   * @param {*} species The species to set as selected.
   */
  setSelected(species) {
    this.props.setSpecies(species);
    history.push(
      `/${
        this.props.match.params.graph_type
          ? this.props.match.params.graph_type
          : "overview"
      }/${species.code}`
    );
    // document.getElementById(`species-list-item-${species.id}`).scrollIntoView();
  }

  render() {
    return (
      <div className="app-container row wrap no-padding no-margin">
        <div
          ref="left_side"
          className="col-xs-12 col-xl-4 left-side no-padding"
        >
          <div
            className="map-container"
            style={{
              width: this.state.map_width + 'px',
              height: (this.state.map_width / 2.053353659) + 'px'
            }}
          >
            <MapArea
              selected_species={this.props.selected_species}
              map_width={this.state.map_width}
            />
          </div>
          <div className="species-container">
            <SectionInformation
              sectionName={this.state.currentSection}
              sectionInfo="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur."
            />
          </div>
        </div>
        <div className="col-xs-12 col-xl-8 right-side no-padding" ref="right_side">
          <AnalysisPanel
            selected_species={this.props.selected_species}
            activeGraph={this.props.match.params.graph_type}
            setCurrentSection={(section) => this.setState({currentSection: section}) }
          />
        </div>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  { setSpecies }
)(Dashboard);
