import React from "react";
import { setSpecies } from "../../redux/actions/speciesAction";
import { connect } from "react-redux";
import { RepositoryFactory } from "../../repositories/RepositoryFactory";
import { Row, Col } from "reactstrap";
import history from "../../history";
import BiomassTrendsSingle from "./BiomassTrendsSingle";

const ROWS = 6;
const COLS = 6;

class BiomassTrends extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      biomass_data: [],
      speciesRepository: RepositoryFactory.get("species"),
      count: 0
    };

    this.setSelectedSpeciesByCode = this.setSelectedSpeciesByCode.bind(this);
  }

  setSelectedSpeciesByCode(code) {
    this.state.speciesRepository.getSingleSpecies(code).then(response => {
      if (response.data[0].code) {
        history.push(`/biomass/${response.data[0].code}`);
      }
    });
  }

  componentDidMount() {
    this.props.toggle("Biomass Trends");
    this.state.speciesRepository
      .getSpeciesBiomass()
      .then(biomassDataResponse => {
        this.state.speciesRepository
          .getSpeciesBiomassYears()
          .then(biomassYearsResponse => {
            this.setState({
              biomass_data: biomassDataResponse.data,
              year_data: biomassYearsResponse.data
            });
          });
      });
  }

  getCols(row_num) {
    let cols = [];
    for (let i = 0; i < COLS; i++) {
      let species_index = (row_num * COLS) + i;
      if (species_index < this.state.biomass_data.length) {
        let chart_id = `biomass-chart-${row_num}-${i}`;
        cols.push(
          <Col xl="2" sm="3" className="no-padding" key={i}>
            <div className="biomass-grid-graph">
              <BiomassTrendsSingle
                id={chart_id}
                className="d3-chart"
                speciesBiomass={this.state.biomass_data[species_index]}
                year_data={this.state.year_data}
                height={this.props.height / (ROWS + 1.5)}
                width={this.props.width / (COLS + 0.5)}
              />
              <div id={chart_id} className="d3-chart" />
            </div>
          </Col>
        );
      }
    }
    return cols;
  }

  getRows() {
    let cols = [];
    for (let i = 0; i < ROWS; i++) {
      cols.push(<Row key={i}>{this.getCols(i)}</Row>);
    }
    return cols;
  }

  render() {
    return <div className="species-biomass-grid">{this.getRows()}</div>;
  }
}

export default connect(
  null,
  { setSpecies }
)(BiomassTrends);
