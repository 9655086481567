import { SET_SPECIES, SET_SECONDARY_SPECIES, SET_SECONDARY_SPECIES_MODE } from "../constants/action-types";

export function setSpecies(species) {
    return {
      type: SET_SPECIES,
      species
    }
  }

export function setSecondarySpecies(species) {
  return {
    type: SET_SECONDARY_SPECIES,
    species
  }
}

export function setSecondarySpeciesMode(modeActive) {
  return {
    type: SET_SECONDARY_SPECIES_MODE,
    modeActive
  }
}
  
