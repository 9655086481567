import { SET_SPECIES, SET_SECONDARY_SPECIES, SET_SECONDARY_SPECIES_MODE } from "../constants/action-types";

const initialState = {
    selectedSpecies: {},
    selectedSecondarySpecies: {},
    secondaryModeActive: false
}

  export default function rootReducer(state = initialState, action) {
    if (action.type === SET_SPECIES) {
        return {
            ...state,
            selectedSpecies: action.species
        };
    }

    else if (action.type === SET_SECONDARY_SPECIES) {
        return {
            ...state,
            selectedSecondarySpecies: action.species
        };
    }

    else if (action.type === SET_SECONDARY_SPECIES_MODE) {
        return {
            ...state,
            secondaryModeActive: action.modeActive
        };
    }
    return state;
}

