import React from "react";
import * as d3 from "d3";
import { setSpecies } from "../../redux/actions/speciesAction";
import { connect } from "react-redux";
import { RepositoryFactory } from "../../repositories/RepositoryFactory";
import history from "../../history";

const mapStateToProps = state => {
  return {
    selectedSecondarySpecies: state.selectedSecondarySpecies,
    secondaryModeActive: state.secondaryModeActive
  };
};

class PreyChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      speciesRepository: RepositoryFactory.get("species")
    };

    this.setSelectedSpeciesByCode = this.setSelectedSpeciesByCode.bind(this);
  }

  setSelectedSpeciesByCode(code) {
    this.state.speciesRepository.getSingleSpecies(code).then(response => {
      this.props.setSpecies(response.data[0]);
      history.push(`/prey/${response.data[0].code}`);
    });
  }

  componentDidMount() {
    this.props.toggle("Prey Plot");
    this.state.speciesRepository.getSpeciesPreyData().then(response => {
      this.setState({ data: response.data });
      this.drawChart(response.data);
    });
  }

  componentDidUpdate(prevProps) {
    if (
      (prevProps.height !== this.props.height ||
        prevProps.width !== this.props.width ||
        prevProps.selectedSpecies !== this.props.selectedSpecies ||
        prevProps.selectedSecondarySpecies !== this.props.selectedSecondarySpecies ||
        prevProps.secondaryModeActive !== this.props.secondaryModeActive) &&
      this.state.data
    ) {
      d3.select("#" + this.props.id + "svg").remove();
      this.drawChart(this.state.data);
    }
  }

  drawChart(data) {
    var margin = { top: 70, right: 40, bottom: 20, left: 130 },
      width = this.props.width - margin.left - margin.right,
      height = this.props.height - margin.top - margin.bottom;

    var dimensions = [
      {
        name: "predator",
        scale: d3.scale.ordinal().rangePoints([0, height]),
        type: String
      },
      {
        name: "Algae",
        scale: d3.scale.linear().range([height, 0]),
        type: Number
      },
      {
        name: "Bacteria",
        scale: d3.scale.linear().range([height, 0]),
        type: Number
      },
      {
        name: "Bird",
        scale: d3.scale.linear().range([height, 0]),
        type: Number
      },
      {
        name: "Coelenterate",
        scale: d3.scale.linear().range([height, 0]),
        type: Number
      },
      {
        name: "Crustacean",
        scale: d3.scale.linear().range([height, 0]),
        type: Number
      },
      {
        name: "Detritus",
        scale: d3.scale.linear().range([height, 0]),
        type: Number
      },
      {
        name: "Echinoderm",
        scale: d3.scale.linear().range([height, 0]),
        type: Number
      },
      {
        name: "Elasmobranch",
        scale: d3.scale.linear().range([height, 0]),
        type: Number
      },
      {
        name: "Microzooplankton",
        scale: d3.scale.linear().range([height, 0]),
        type: Number
      },
      {
        name: "Mollusc",
        scale: d3.scale.linear().range([height, 0]),
        type: Number
      },
      {
        name: "Phytoplankton",
        scale: d3.scale.linear().range([height, 0]),
        type: Number
      },
      {
        name: "Polychaete",
        scale: d3.scale.linear().range([height, 0]),
        type: Number
      },
      {
        name: "Teleost",
        scale: d3.scale.linear().range([height, 0]),
        type: Number
      },
      {
        name: "Tunicate",
        scale: d3.scale.linear().range([height, 0]),
        type: Number
      }
    ];

    var x = d3.scale
      .ordinal()
      .domain(
        dimensions.map(function(d) {
          return d.name;
        })
      )
      .rangePoints([0, width]);

    var line = d3.svg.line().defined(function(d) {
      return !isNaN(d[1]);
    });

    var yAxis = d3.svg.axis().orient("left");

    var svg = d3
      .select("#" + this.props.id)
      .append("svg")
      .attr("id", this.props.id + "svg")
      .attr("width", this.props.width + "px")
      .attr("height", this.props.height + "px")
      .append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    var dimension = svg
      .selectAll(".dimension")
      .data(dimensions)
      .enter()
      .append("g")
      .attr("class", "dimension")
      .attr("transform", function(d) {
        return "translate(" + x(d.name) + ")";
      });

    dimensions.forEach(function(dimension) {
      dimension.scale.domain(
        dimension.type === Number
          ? [0, 100]
          : data
              .map(function(d) {
                return d[dimension.name];
              })
              .sort()
      );
    });

    svg
      .append("g")
      .attr("class", "foreground")
      .selectAll("path")
      .data(data)
      .enter()
      .append("path")
      .attr("d", draw);

    svg
      .append("g")
      .attr("class", "background")
      .selectAll("path")
      .data(data)
      .enter()
      .append("path")
      .attr("class", d => {
        if (
          this.props.selectedSpecies &&
          d.predator === this.props.selectedSpecies.com_name
        ) {
          return "selected-species-path";
        } else if (
          this.props.selectedSecondarySpecies &&
          this.props.secondaryModeActive &&
          d.predator === this.props.selectedSecondarySpecies.com_name
        ) {
          return "secondary-species-path";
        } else {
          return "";
        }
      })
      .attr("d", draw);

    dimension
      .append("g")
      .attr("class", "axis")
      .each(function(d) {
        d3.select(this).call(yAxis.scale(d.scale));
      })
      .append("text")
      .attr("class", "title")
      .attr("text-anchor", "end")
      .attr("transform", function(d) {
        if (d.name !== "predator") {
          return "rotate(20)";
        } else {
          return "";
        }
      })
      .attr("y", -15)
      .text(function(d) {
        return d.name;
      });

    // Rebind the axis data to simplify mouseover.
    svg
      .select(".axis")
      .selectAll("text:not(.title)")
      .data(data, function(d) {
        return d.predator || d;
      })
      .attr("class", d => {
        if (
          this.props.selectedSpecies &&
          d.predator === this.props.selectedSpecies.com_name
        ) {
          return "selected-species-label label";
        } else if (
          this.props.selectedSecondarySpecies &&
          this.props.secondaryModeActive &&
          d.predator === this.props.selectedSecondarySpecies.com_name
        ) {
          return "secondary-species-label label";
        } else {
          return "label";
        }
      });

    svg.selectAll(".foreground path").attr("class", "inactive");

    var projection = svg
      .selectAll(".axis text,.background path,.foreground path")
      .on("mouseover", mouseover)
      .on("mouseout", mouseout)
      .on("click", click);

    function click(d) {
      // Bit trickier to do this one since no code in the table. ??
      // setSelectedSpeciesByCode(d.predator);
    }

    function mouseout() {
      projection.classed("inactive", () => true);
    }

    function mouseover(d) {
      svg.classed("active", true);
      projection.classed("inactive", function(p) {
        return p !== d;
      });
      projection
        .filter(function(p) {
          return p === d;
        })
        .each(moveToFront);
    }

    function moveToFront() {
      this.parentNode.appendChild(this);
    }

    function draw(d) {
      return line(
        dimensions.slice(1, dimensions.length).map(function(dimension) {
          return [x(dimension.name), dimension.scale(d[dimension.name] * 100)];
        })
      );
    }
  }

  render() {
    return <div />;
  }
}

export default connect(
  mapStateToProps,
  { setSpecies }
)(PreyChart);
