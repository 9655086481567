import React from "react";
import Plot from "react-plotly.js";
import { setSpecies } from "../redux/actions/speciesAction";
import { connect } from "react-redux";

const mapStateToProps = state => {
  return { selected_species: state.selectedSpecies };
};

class OverviewDetailsChart extends React.Component {

  compareSpecies(a, b, sortingValue) {
    // only for undefined
    if (typeof a[sortingValue] === "undefined") return 1;
    if (typeof b[sortingValue] === "undefined") return 0;

    a = a[sortingValue];
    b = b[sortingValue];

    return a < b ? 1 : a > b ? -1 : 0;
  }

  // Filters the catch values to only include those corresponding to related species
  filterData() {
    return this.props.info.sort((a, b) =>
      this.compareSpecies(a, b, this.props.infoType)
    );
  }

  render() {
    const info = this.filterData();
    return (
      <div>
        {info.length > 1 ? (
          <div>
            <Plot
              className="bar-chart"
              data={[
                {
                  type: "bar",
                  x: info.map(a => a.code),
                  y: info.map(a => a[this.props.infoType] / this.props.scaleValue),
                  hovertemplate: "%{y}",
                  name: "",
                }
              ]}
              layout={{
                xaxis: { title: { text: "Species code or group" } },
                yaxis: { title: { text: this.props.infoTitle } },
              }}
            />
          </div>
        ) : (
          <div className="no-data-available">No Data Avaliable</div>
        )}
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  { setSpecies }
)(OverviewDetailsChart);
