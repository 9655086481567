import React from "react";
import { RepositoryFactory } from "../repositories/RepositoryFactory";
import Plot from "react-plotly.js";
import { setSpecies } from "../redux/actions/speciesAction";
import { connect } from "react-redux";

const mapStateToProps = state => {
  return { selected_species: state.selectedSpecies };
};

class AnnualCatchesChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      speciesRepository: RepositoryFactory.get("species"),
      annual_catches: [],
      width: 100
    };
  }

  componentDidMount() {
    // Initialises all the related species array for all species.
    this.props.toggle("Species Details");
    this.state.speciesRepository
      .getAnnualCatchesForSpecies(this.props.selected_species.code)
      .then(response => {
        this.setState({ annual_catches: response.data });
      });
  }

  componentDidUpdate(prevProps) {
    // If the species list has updated, that is the user has clicked on another species.
    if (this.props.selected_species !== prevProps.selected_species) {
      // Gets all the related species for the selected species.
      this.state.speciesRepository
        .getAnnualCatchesForSpecies(this.props.selected_species.code)
        .then(response => {
          this.setState({ annual_catches: response.data });
        });
    }
  }

  // Ensures any null data is displayed as 0 for item catch.
  filterData() {
    let after_first = false;
    let catches = [];
    this.state.annual_catches.forEach(item => {
      if (item.catch !== null || after_first) {
        after_first = true;
        catches.push({
          species: item.species,
          catch: item.catch ? item.catch : 0,
          year: item.year
        });
      }
    });

    return catches;
  }

  render() {
    const catches = this.filterData();
    return (
      <div>
        {catches.length > 1 ? (
          <div>
            <Plot
              ref="bycatch_chart"
              className="bar-chart bycatch-chart"
              data={[
                {
                  type: "bar",
                  x: catches.map(a => a.year),
                  y: catches.map(a => a.catch / (1 * Math.pow(10,3))),
                  hovertemplate: "%{y} kg",
                  name: ""
                }
              ]}
              layout={{
                xaxis: { title: { text: "Year" }},
                yaxis: { title: { text: "Catch (x 1000 tonnes)" }, 'tickformat': ',d'  },
                title:
                  `Annual reported commercial catch of  ${ this.props.selected_species.com_name}`,
                width: this.props.width,
                height: (this.props.height - 100) > 400 ? (this.props.height - 100) : 400
              }}
            />
          </div>
        ) : (
          <div></div>
        )}
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  { setSpecies }
)(AnnualCatchesChart);
