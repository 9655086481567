import React from "react";
import * as d3 from "d3";
import { setSpecies } from "../../redux/actions/speciesAction";
import { connect } from "react-redux";

class BiomassTrends extends React.Component {
  
  componentDidMount() {
    this.drawChart(
      this.props.speciesBiomass ? [this.props.speciesBiomass] : [],
      this.props.year_data
    );
  }

  componentDidUpdate(prevProps) {
    if (
      (prevProps.height !== this.props.height ||
        prevProps.width !== this.props.width ||
        prevProps.speciesBiomass !== this.props.speciesBiomass) &&
      this.props.year_data &&
      this.props.speciesBiomass
    ) {
      d3.select("#" + this.props.id + "svg").remove();
      this.drawChart(
        this.props.speciesBiomass ? [this.props.speciesBiomass] : [],
        this.props.year_data
      );
    }
  }

  drawChart(data, years) {
    var margin = { top: 10, right: 15, bottom: 0, left: 0 },
      width = this.props.width - margin.left - margin.right,
      height = this.props.height - margin.top - margin.bottom;

    var dimensions = years.map(year => {
      return {
        name: year.year,
        scale: d3.scale.linear().range([height, 0]),
        type: Number
      };
    });

    dimensions.unshift({
      name: "species",
      scale: d3.scale.ordinal().rangePoints([0, height]),
      type: String
    });

    var x = d3.scale
      .ordinal()
      .domain(
        dimensions.map(function(d) {
          return d.name;
        })
      )
      .rangePoints([0, width]);

    var line = d3.svg.line().defined(function(d) {
      return !isNaN(d[1]);
    });

    var svg = d3
      .select("#" + this.props.id)
      .append("svg")
      .attr("id", this.props.id + "svg")
      .attr("width", this.props.width + "px")
      .attr("height", this.props.height + "px")
      .append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    svg
      .append("text")
      .attr("x", width - 20)
      .attr("y", margin.top)
      .attr("text-anchor", "middle")
      .style("font-size", "15px")
      .text(data ? data[0].species : "");

    var area = d3.svg
      .area()
      .x(function(d) {
        return d.x;
      })
      .y0(function(d) {
        return d.y0;
      })
      .y1(function(d) {
        return d.y1;
      });
      
    dimensions.forEach(function(dimension) {
      dimension.scale.domain(
        dimension.type === Number
          ? [
              0,
              4.5
            ]
          : data
              .map(function(d) {
                return d[dimension.name];
              })
              .sort()
      );
    });

    svg
      .append("g")
      .attr("class", "foreground")
      .selectAll("path")
      .data(data)
      .enter()
      .append("path")
      .attr("d", draw);

    svg
      .append("g")
      .attr("class", "background")
      .selectAll("path")
      .data(data)
      .enter()
      .append("path")
      .attr("class", d =>
        this.props.selectedSpecies &&
        d.species === this.props.selectedSpecies.code
          ? "selected-species-path"
          : ""
      )
      .attr("d", draw);

    svg
      .append("g")
      .attr("class", "areaforeground")
      .selectAll("path")
      .data(formatData())
      .enter()
      .append("path")
      .attr("d", areaFunction);

    svg.selectAll(".foreground path").attr("class", "active");
    svg.selectAll(".areaforeground path").attr("class", "active");

    function areaFunction(d) {
      return area(d.slice(1, d.length));
    }

    function draw(d) {
      return line(
        dimensions.map(function(dimension) {
          return [
            x(dimension.name),
            dimension.scale(d[dimension.name]["n_biomass"])
          ];
        })
      );
    }

    function formatData() {
      let formattedData = data.map(d =>
        dimensions.map(function(dimension) {
          if (dimension.name === "species") {
            return d[dimension.name];
          }
          return {
            x: x(dimension.name),
            y0: dimension.scale(d[dimension.name]["n_lbound"]),
            y1: dimension.scale(d[dimension.name]["n_ubound"])
          };
        })
      );
      return formattedData;
    }
  }

  render() {
    return <div />;
  }
}

export default connect(
  null,
  { setSpecies }
)(BiomassTrends);
