import React from "react";

export default class AnalysisHeader extends React.Component {
  render() {
    if (this.props.selected_species && this.props.selected_species.com_name) {
      return (
        <div className="analysis-header">
          <div>
            <h2>{this.props.selected_species.com_name}</h2>
            <i><h4>{this.props.selected_species.sci_name}</h4></i>
          </div>

          <div>
            <h4>Species Code</h4>
            <h2>{this.props.selected_species.code}</h2>
          </div>
        </div>
      );
    } else {
      return (
        <div className="analysis-header">
          <h2>No Species Selected</h2>;
        </div>
      );
    }
  }
}
